import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'CurrencyId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CurrencyNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurrencyNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurrencyNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurrencyNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurrencyNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurrencyNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurrencyCode',
		i18n: 'currencycode',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurrencyAbbr',
		i18n: 'currencysymbol',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurrencyRate',
		i18n: 'exchangerateaga',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
];
